import React from 'react'
import { Link } from 'gatsby'
import BuildingImage from '../assets/images/svg/mags-building.svg'
import { plantinBoldItalic, buttonStyles, buttonHoverStyles } from '../styles'
import Layout from '../components/layout'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const ThankYouWrap = styled.div({
  minHeight: '80vh',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
})

const ThankYouHeader = styled.h1({
  fontFamily: plantinBoldItalic,
  letterSpacing: -0.5,
  fontSize: '4.4rem',
  marginBottom: '1.5rem',
})

const styles = {
  stroke: 'red',
  fill: 'red',
  width: '100%',
  maxWidth: '100%',
}

const ImageWrapper = styled.div(
  {
    marginBottom: '3rem',
    maxWidth: '100%',
    padding: '0 2rem',
  },
  () => ({
    '@media screen and (min-width: 720px)': {
      maxWidth: '50%',
    },
  })
)

const ReturnLink = styled.div({
  margin: '2rem 0',
})

const ThankYouButton = css`
  ${buttonHoverStyles}
  ${buttonStyles}
  text-decoration: none;
  padding: 2rem;
`

const ThankYouPage = () => (
  <Layout>
    <ThankYouWrap>
      <ImageWrapper>
        <BuildingImage style={styles} />
      </ImageWrapper>
      <ThankYouHeader>Thank You For Your Order!</ThankYouHeader>
      <ReturnLink>
        <Link css={ThankYouButton} to="/">
          Return to our home page
        </Link>
      </ReturnLink>
    </ThankYouWrap>
  </Layout>
)

export default ThankYouPage
